import { NavLink, type NavLinkProps } from '@remix-run/react'
import { type ElementRef, forwardRef, useRef, type MutableRefObject, type RefCallback } from 'react'
import { useLink, mergeProps, type AriaLinkOptions, useHover, useFocusRing } from 'react-aria'
import { cn } from '#app/utils/tailwind-merge.ts'

export interface LinkProps extends AriaLinkOptions, Omit<NavLinkProps, 'download' | 'onBlur' | 'onFocus' | 'onKeyDown' | 'onKeyUp'> {
	variant?: 'default' | 'button' | 'ghost'
	size?: 'sm' | 'md' | 'lg'
}

const Link = forwardRef<ElementRef<typeof NavLink>, LinkProps>(({ to = '/', variant = 'default', size, children, className, ...props }, ref) => {
	const privateRef = useRef(null)
	const { linkProps, isPressed } = useLink(props, privateRef)
	const { hoverProps, isHovered } = useHover(props)
	const { focusProps, isFocused, isFocusVisible } = useFocusRing()
	const mergedProps = mergeProps(props, linkProps, hoverProps, focusProps)
	return (
		<NavLink
			ref={mergeRefs(privateRef, ref)}
			to={to}
			className={cn('a', variant, variant === 'button' ? 'primary' : null, size, className)}
			{...mergedProps}
			data-focused={isFocused || undefined}
			data-hovered={isHovered || undefined}
			data-pressed={isPressed || undefined}
			data-focus-visible={isFocusVisible || undefined}
			data-current={!!props['aria-current'] || undefined}
			data-disabled={props.isDisabled || undefined}
		>
			{children}
		</NavLink>
	)
})
Link.displayName = 'Link'

type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>

export function mergeRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
	return (val: T) => {
		setRef(val, ...refs)
	}
}
export function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
	refs.forEach(ref => {
		if (typeof ref === 'function') {
			ref(val)
		} else if (ref != null) {
			ref.current = val
		}
	})
}

export { Link }
